import React, {useEffect, useState} from 'react';
import './GlobalConfirm.scss';
import {Button, Modal} from 'react-bootstrap';
import { ConfirmDialogUtility } from '../../utils/ConfirmDialogUtility';
import SimpleTextArea from '../shared/SimpleTextArea/SimpleTextArea';
import { library } from '@practicehub/assets';
import { faExclamationCircle as faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
library.add(faExclamationCircle);

const GlobalConfirm = () => {
    const [show, setShow] = useState(false);
    const [includeNote, setIncludeNote] = useState(false);
    const [note, setNote] = useState('');
    const [noteError, setNoteError] = useState('');
    const [message, setMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    function processResult(result) {

        if(result && includeNote && !note) {
            setNoteError("Please provide a file note.");
            return;
        }

        setShow(false);
        ConfirmDialogUtility.resultHandler(result, note);
    }

    useEffect(() => {
        ConfirmDialogUtility.displayHandler = (displayMessage, includeNote) => {
            setNote('');
            setNoteError('');
            setMessage(displayMessage);
            setIncludeNote(includeNote);
            setShow(true);
        };
        ConfirmDialogUtility.alertHandler = alertDisplayMessage => {
            setAlertMessage(alertDisplayMessage);
        };
    }, []);

    return <Modal show={show} centered>
        <Modal.Header>
            <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {alertMessage &&
                <div className="alert alert-danger show" role="alert">
                    <i className="fa fa-exclamation-circle"></i>
                    <span> {alertMessage}</span>
                </div>
            }
            <p>{message}</p>
            {includeNote && 
                <div className="row">
                    <div className="col-12">
                        <SimpleTextArea
                            title=""
                            value={note}
                            error={noteError}
                            onChange={value => setNote(value.target.value)}
                        />
                    </div>
                </div>
            }
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => processResult(false)}>No</Button>
            <Button variant="primary" onClick={() => processResult(true)}>Yes</Button>
        </Modal.Footer>
    </Modal>;
};

export default GlobalConfirm;
