import React, {useContext} from 'react';
import {PermissionsContext} from '../../PermissionsContext';
import {Popover} from '../Common/Popover';
import {Status} from '../Common/Status';
import {AHPRAStatus} from '../shared/AHPRAStatus/AHPRAStatus';
import {ProvisioningStatusLookUp, AlertStatus} from '../../Constants';
import { convertUtcDateToLocal } from '../../formatters';
import { Link } from 'react-router-dom';
import { library } from '@practicehub/assets';
import { faEllipsisV as farEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
library.add(farEllipsisV);

function renderAlerts(alerts, alertType){
    if(alertType == AlertStatus.NEW && alerts.filter(a=> a.status == AlertStatus.NEW).length == 0){
        return;
    }
    if(alertType == AlertStatus.OPEN && alerts.filter(a=> a.status == AlertStatus.OPEN).length == 0){
        return;
    }
    if(alertType == AlertStatus.OVERDUE && alerts.filter(a=> a.status == AlertStatus.OVERDUE).length == 0){
        return;
    }

    const classType = () => {
        switch (alertType) {
            case AlertStatus.NEW:
                return 'alert';
            case AlertStatus.OPEN:
                return 'warning';
            case AlertStatus.OVERDUE:
                return 'alert'
            default:
                return null;
        }
    };
    const icon = () => {
        switch (alertType) {
            case AlertStatus.NEW:
                return 'alert';
            case AlertStatus.OPEN:
                return 'warning';
            case AlertStatus.OVERDUE:
                return 'alert'
            default:
                return null;
        }
    };
    const countLabel = () => {
        if(alertType === AlertStatus.NEW){
            return alerts.filter(a=> a.status == AlertStatus.NEW).length;
        }else if (alertType === AlertStatus.OPEN){
            return alerts.filter(a=> a.status == AlertStatus.OPEN).length;
        }else if (alertType === AlertStatus.OVERDUE){
            return alerts.filter(a=> a.status == AlertStatus.OVERDUE).length;
        }else{
            return null;
        }
    }
    
    return <><Status countlabel={countLabel()} label={alertType} classtype={classType()} icon={icon()} width='100px'></Status>{' '}</>;
}

function renderRowMenu(registrationRecord, canManage, activateRecord, deactivateRecord, setRecordInactive) {
    return <>
                <a className="ellipsis-dropdown" data-toggle="dropdown">
                    <i className="far fa-ellipsis-v fa-fw"></i>
                </a>

                <div className="dropdown-menu dropdown-menu-right">
                {(registrationRecord.provisioningStatus === 'Active' || registrationRecord.provisioningStatus === 'New' || registrationRecord.provisioningStatus === 'NewNoLicense') &&
                    <>
                        <Link to={`/record/${registrationRecord.linkedRecordId}`}
                            className="dropdown-item">
                            {registrationRecord.provisioningStatus === 'NewNoLicense' ? `View` : `Edit`}
                        </Link>
                {canManage && <a className="dropdown-item" onClick={() => deactivateRecord(registrationRecord)}>Deactivate</a>}
                    </>
                }
                {(registrationRecord.provisioningStatus === 'Deactivated' || registrationRecord.provisioningStatus === 'Inactive') &&
                    <>
                        <Link to={`/record/${registrationRecord.linkedRecordId}`}
                            className="dropdown-item">
                            View
                        </Link>
                        {canManage && <a className="dropdown-item" onClick={() => activateRecord(registrationRecord)}>Activate</a>}
                    </>
                }
                {registrationRecord.provisioningStatus === 'Deactivated' && canManage && 
                    <a className="dropdown-item" onClick={() => setRecordInactive(registrationRecord)}>Inactive</a>
                }
                {registrationRecord.provisioningStatus === 'NoRecord' && canManage && 
                    <a className="dropdown-item" onClick={() => activateRecord(registrationRecord)}>Activate</a>
                }
                </div>
            </>;
}

function renderAhpraDetail(ahpra, registrationRecord){
    //Card#6456 remove duplicate Ahpra professions from the list
    const uniqueProfessions = [...new Set(ahpra.profession?.map(p => p.name).filter(name => name))] || [];
    return  <>
                <td>
                    {/* Card#5741 - if profession has no record, then display the clinical service type as value */}
                    {(!uniqueProfessions || uniqueProfessions.length === 0) && ahpra.professionStr}
                    {uniqueProfessions && uniqueProfessions.length === 1 && uniqueProfessions[0]}
                    {uniqueProfessions && uniqueProfessions.length > 1 && 
                        <Popover label="profession" countlabel={uniqueProfessions.length} title="Profession">
                            {uniqueProfessions.map((name, index) =>
                                <div key={index}>{name}</div>
                            )}
                        </Popover>
                    }
                </td>
                <td>
                    {ahpra.expiryDate ? convertUtcDateToLocal(ahpra.expiryDate) : ''}
                </td>
                <td>
                    {ahpra.updatedDate ? convertUtcDateToLocal(ahpra.updatedDate) : ''}
                </td>
                <td>
                    <div>
                        <AHPRAStatus status={ahpra.ahpraStatus}></AHPRAStatus>
                    </div>
                </td>
                <td>
                    {ahpra.stipulations ? convertUtcDateToLocal(ahpra.stipulations) : ''}
                </td>
                <td>
                    <div>
                    {registrationRecord.alerts && registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == ahpra.ahpraRegistrationNumber).length >= 1 && 
                        renderAlerts(registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == ahpra.ahpraRegistrationNumber), AlertStatus.NEW)
                    }
                    {registrationRecord.alerts && registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == ahpra.ahpraRegistrationNumber).length >= 1 && 
                        renderAlerts(registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == ahpra.ahpraRegistrationNumber),AlertStatus.OPEN)
                    }
                    {registrationRecord.alerts && registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == ahpra.ahpraRegistrationNumber).length >= 1 && 
                        renderAlerts(registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == ahpra.ahpraRegistrationNumber),AlertStatus.OVERDUE)
                    }
                    </div>
                </td>
                <td className="text-right">
                    
                </td>
            </>;
}

const RegistrationListRow = ({registrationRecord, activateRecord, deactivateRecord, setRecordInactive, sendConsentEmail}) => { 
    const { canManageSite, isImpersonation, impersonationId, canManage } = useContext(PermissionsContext);

    const canManageOrPHAdmin = canManage || (isImpersonation && impersonationId != null && impersonationId !== undefined && impersonationId == '00000000-0000-0000-0000-000000000000')

    const isSingleRegistration = !registrationRecord.ahpraRecords || registrationRecord.ahpraRecords.length == 1;
    const singleAhpraRegistration = registrationRecord.ahpraRecords[0];

    //Card#6456 remove duplicate Ahpra professions from the list
    const uniqueProfessions = [...new Set(singleAhpraRegistration?.profession?.map(p => p.name).filter(name => name))] || [];

    // US#15764 - Dynamic Decoupling - covers when user/staff not migrated or not found yet.
    // Note: Organisation or user or Affiliation record are not found 
    let fullName = registrationRecord && registrationRecord.firstName && registrationRecord.lastName ? `${registrationRecord.firstName} ${registrationRecord.lastName}` : 
                   registrationRecord.isStaffRecord ? "Staff Not Found/Not Migrated yet" : 
                   registrationRecord.isUserRecord ? "User Not Found/Not Migrated yet" : "-";

    return (<React.Fragment>
                <tr>
                    <td>
                        {registrationRecord.provisioningStatus !== 'NoRecord' &&
                            <Link to={`/record/${registrationRecord.linkedRecordId}`}>
                                {fullName}
                            </Link>
                        }
                        {registrationRecord.provisioningStatus === 'NoRecord' &&
                            fullName
                        }
                    </td>
                    <td>
                        {ProvisioningStatusLookUp[registrationRecord.provisioningStatus]}
                    </td>
                    {canManageSite && 
                        <td>
                            {registrationRecord.affiliations && registrationRecord.affiliations.length === 1 && registrationRecord.affiliations[0].name}
                            {registrationRecord.affiliations && registrationRecord.affiliations.length > 1  &&
                                <Popover label="practices" countlabel={registrationRecord.affiliations.length} title="Practices">
                                    {registrationRecord.affiliations.map((p, index) =>
                                        <div key={index}>{p.name}</div>
                                    )}
                                </Popover>
                            }
                        </td>
                    }
                    <td>
                        {isSingleRegistration && uniqueProfessions && uniqueProfessions.length === 1 && uniqueProfessions[0]}
                        {isSingleRegistration && uniqueProfessions && uniqueProfessions.length > 1 && 
                            <Popover label="profession" countlabel={uniqueProfessions.length} title="Profession">
                                {uniqueProfessions.map((name, index) =>
                                    <div key={index}>{name}</div>
                                )}
                            </Popover>
                        }
                    </td>
                    <td>
                        {isSingleRegistration && singleAhpraRegistration.expiryDate ? convertUtcDateToLocal(singleAhpraRegistration.expiryDate) : ''}
                    </td>
                    <td>
                        {isSingleRegistration && singleAhpraRegistration.updatedDate ? convertUtcDateToLocal(singleAhpraRegistration.updatedDate) : ''}
                    </td>
                    <td>
                        {isSingleRegistration && 
                            <div>
                                <AHPRAStatus status={singleAhpraRegistration.ahpraStatus}></AHPRAStatus>
                            </div>
                        }
                    </td>
                    <td>
                        {isSingleRegistration && singleAhpraRegistration.stipulations ? convertUtcDateToLocal(singleAhpraRegistration.stipulations) : ''}
                    </td>
                    <td>
                        <div>
                            {!isSingleRegistration && registrationRecord.alerts && registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == undefined || a.ahpraRegistrationNumber == '').length >= 1 && 
                                renderAlerts(registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == undefined || a.ahpraRegistrationNumber == ''), AlertStatus.NEW)
                            }
                            {!isSingleRegistration && registrationRecord.alerts && registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == undefined || a.ahpraRegistrationNumber == '').length >= 1 && 
                                renderAlerts(registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == undefined || a.ahpraRegistrationNumber == ''),AlertStatus.OPEN)
                            }
                            {!isSingleRegistration && registrationRecord.alerts && registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == undefined || a.ahpraRegistrationNumber == '').length >= 1 && 
                                renderAlerts(registrationRecord.alerts.filter(a => a.ahpraRegistrationNumber == undefined || a.ahpraRegistrationNumber == ''),AlertStatus.OVERDUE)
                            }
                            {isSingleRegistration && registrationRecord.alerts && registrationRecord.alerts.length >= 1 && 
                                renderAlerts(registrationRecord.alerts, AlertStatus.NEW)
                            }
                            {isSingleRegistration && registrationRecord.alerts && registrationRecord.alerts.length >= 1 && 
                                renderAlerts(registrationRecord.alerts,AlertStatus.OPEN)
                            }
                            {isSingleRegistration && registrationRecord.alerts && registrationRecord.alerts.length >= 1 && 
                                renderAlerts(registrationRecord.alerts,AlertStatus.OVERDUE)
                            }
                        </div>
                    </td>
                    <td>
                {(!registrationRecord.provisioningStatus === 'NoRecord' || canManageOrPHAdmin) &&
                            renderRowMenu(registrationRecord,canManageOrPHAdmin,activateRecord, deactivateRecord, setRecordInactive)
                        }
                    </td>
                </tr>
                {!isSingleRegistration && registrationRecord.ahpraRecords && registrationRecord.ahpraRecords.length > 0 && registrationRecord.ahpraRecords.map((ahpra, i) => 
                    <tr key={i}>
                        <td colSpan={!canManageSite ? "2" : "3"} style={{border: 'none'}}>&nbsp;</td>
                        {renderAhpraDetail(ahpra,registrationRecord)}
                    </tr>
                )}
            </React.Fragment>
            );
}

export default RegistrationListRow;
