import React from 'react';
import './badge.scss';
import { classNames, emptyFn } from 'utils/util';
import IconButton from 'components/IconButton/IconButton';
import { faTimes as faLightTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { library } from 'components/Icons/Icon';
library.add(faLightTimes);

export interface BadgeProps {
	content: string;
	type?: BadgeType;
	size?: BadgeSize;
	dismissible?: boolean;
	onDismiss?: () => void;
	onClick?: () => void;
}

export type BadgeType = 'default' | 'success' | 'info' | 'error' | 'warning';
export type BadgeSize = 'small' | 'medium' | 'large';

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(

	({ content, type = 'default', size = 'large', dismissible = false, onDismiss, onClick }, ref) => {
		const classes = classNames(['ph-badge', { [type]: type, [size]: size }]);

		return (
			<div className={classes} ref={ref} onClick={!dismissible ? onClick : emptyFn}>
				{content}
				{dismissible && <IconButton icon={['fal', 'times']} onClick={onDismiss} />}
			</div>
		);
	}
);

Badge.displayName = 'Badge';

export default Badge;
