import React from 'react';
import './radio.scss';
import Icon, { library } from 'components/Icons/Icon';
import { classNames } from 'utils/util';
import { ComponentBase } from 'utils/types';
import { faCircle as faRegularCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircleDot as faRegularCircleDot } from '@fortawesome/pro-regular-svg-icons/faCircleDot';

library.add(faRegularCircle, faRegularCircleDot);

export interface RadioProps extends ComponentBase {
	id: string;
	label: string;
	disabled?: boolean;
	name: string;
	value: string;
	checked?: boolean;
	handleRadioChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<RadioProps> = ({
	id,
	label,
	disabled,
	className,
	name,
	value,
	checked = false,
	handleRadioChange,
}) => {
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		id && handleRadioChange?.(event);
	};

	return (
		<div className={classNames(['ph-radio', className])}>
			<input
				id={id}
				type="radio"
				disabled={disabled}
				name={name}
				value={value}
				onChange={handleOnChange}
				checked={checked}
			/>
			<label htmlFor={id}>
				<Icon icon={checked ? ['far', 'circle-dot'] : ['far', 'circle']} />
				{label}
			</label>
		</div>
	);
};

export default Radio;
