import React, { useEffect, useState } from 'react';
import './toast.scss';
import { classNames } from 'utils/util';
import Alert, { AlertStates } from 'components/Alert/Alert';

interface ToastProps {
	state?: keyof typeof AlertStates;
	message: string;
	timeOut?: number;
	header?: string;
	classNames?: string;
}

const Toast: React.FC<ToastProps> = ({ state, header, message, classNames: classNamesProp, timeOut = 2000 }) => {
	const [shouldRender, setShouldRender] = useState(true);
	const [fadeout, setFadeout] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setFadeout(true), timeOut);
		return () => clearTimeout(timer);
	}, []);

	const classes = classNames([
		'ph-toast',
		{
			'ph-open': shouldRender,
			'ph-fadeout': fadeout,
		},
		classNamesProp,
	]);

	const onAnimationEnd = () => {
		if (fadeout) {
			setShouldRender(false);
		}
	};

	if (!shouldRender) {
		return null;
	}

	return (
		<div className={classes} onAnimationEnd={onAnimationEnd}>
			<Alert state={state} header={header} message={message} showCloseButton={false} />
		</div>
	);
};

export default Toast;
