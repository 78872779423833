import React from 'react';
import './retry.scss';
import Button from 'components/Button';
import Icon, { library } from 'components/Icons/Icon';
import { faRotateRight as faLightRotateRight } from '@fortawesome/pro-light-svg-icons/faRotateRight';
library.add(faLightRotateRight);

interface RetryProps {
	fetchData?: () => void;
	heading?: string;
	message?: string;
}

const Retry: React.FC<RetryProps> = ({ fetchData, heading, message }) => {
	return (
		<div className="ph-retry">
			<Icon icon={['fal', 'triangle-exclamation']} />
			<h4>{heading}</h4>
			<p>{message}</p>
			<Button
				size="medium"
				type="secondary"
				buttonText="Retry"
				onClick={fetchData}
				leftIcon={['fal', 'rotate-right']}
			/>
		</div>
	);
};

export default Retry;
