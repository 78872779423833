import React, { useState, useEffect, forwardRef } from 'react';
import './textInput.scss';
import { classNames } from 'utils/util';
import ErrorMessage from 'components/ErrorMessage';
import FormLabel from 'components/FormLabel';
import Input from 'components/Input';
import IconButton from 'components/IconButton';
import { InputTypes } from 'components/Input/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CharLimit from 'components/CharLimit';
import { faXmark as faLightXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import Icon, { library } from 'components/Icons/Icon';
library.add(faLightXmark);

type InputType = (typeof InputTypes)[number];

interface TextInputProps {
	id?: string;
	value?: string;
	label?: string;
	placeholder?: string;
	isMandatory?: boolean;
	error?: string;
	hasClearButton?: boolean;
	onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
	onClear?: (value?: string) => void;
	disabled?: boolean;
	className?: string;
	name?: string;
	type?: InputType;
	leftIcon?: IconProp;
	ignoreSpecialCharacterForNumberInput?: boolean;
	ignoredSpecialCharactersForNumberInput?: string[];
	charLimit?: number;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(
		{
			id,
			value = '',
			label = '',
			placeholder = '',
			isMandatory = false,
			error = '',
			hasClearButton = false,
			onChange,
			onKeyDown,
			onClear,
			className,
			disabled = false,
			name,
			type = 'text',
			leftIcon = undefined,
			charLimit = 0,
			...props
		},
		ref
	) => {
		const [inputValue, setInputValue] = useState(value);
		const [errorMessage, setErrorMessage] = useState(error);
		const prefixClassName = 'ph-text-input';
		const classes = classNames([prefixClassName, { hasNoLabel: !label }, className]);
		const [isInvalid, setIsInvalid] = useState(false);

		useEffect(() => {
			setErrorMessage(error);
		}, [error]);

		useEffect(() => {
			setInputValue(value);
		}, [value]);

		const onInternalClear = () => {
			setInputValue('');
			setErrorMessage('');
			if (onClear) onClear('');
		};

		const onInternalChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
			const value = evt.target.value;
			setInputValue(value);
			if (onChange) onChange(evt);
			charLimit > 0 && value.length > charLimit ? setIsInvalid(true) : setIsInvalid(false);
		};

		return (
			<div className={classes}>
				{label ? (
					<div className="ph-input-label">
						<FormLabel label={label} htmlIdFor={id} isMandatory={isMandatory} />
						<CharLimit
							charLimit={charLimit}
							className={className}
							isInvalid={isInvalid}
							inputValue={inputValue}
						/>
					</div>
				) : null}

				<div className="ph-input-field">
					{leftIcon != undefined && (
						<div className="ph-left-icon">
							<Icon icon={leftIcon} />
						</div>
					)}
					<Input
						id={id}
						placeholder={placeholder}
						value={inputValue}
						onChange={onInternalChange}
						disabled={disabled}
						hasError={!!errorMessage || isInvalid}
						name={name}
						type={type}
						onKeyDown={onKeyDown}
						className={leftIcon != undefined ? 'ph-has-left-icon' : ''}
						ref={ref}
						{...props}
					/>
					{/* Beacuse how && works and can render the output of the first falsy value which not desirable  */}
					{!!inputValue && hasClearButton && (
						<div className="ph-clear-icon">
							<IconButton icon={['fal', 'xmark']} onClick={onInternalClear} />
						</div>
					)}
				</div>
				{errorMessage && <ErrorMessage error={errorMessage} />}
				{isInvalid && <ErrorMessage error={'This field cannot exceed ' + charLimit + ' characters'} />}
			</div>
		);
	}
);

TextInput.displayName = 'TextInput';

export default TextInput;
