import React from 'react';
import './loading.scss';
import Icon, { library } from 'components/Icons/Icon';
import { faSpinner as faSolidSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
library.add(faSolidSpinner);

interface LoadingProps {
	showMessage?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ showMessage = true }) => {
	return (
		<div className="ph-loading">
			<Icon icon={['fas', 'spinner']} className="ph-loading-icon" /> {showMessage && ' Loading...'}
		</div>
	);
};

export default Loading;
