import React, {useState, createContext, useEffect} from 'react';
import * as Api from '../lib/api';

export const PermissionsContext = createContext();

export const PermissionsProvider = (props) => {
    const [isImpersonation, setIsImpersonation] = useState(false);
    const [impersonationId, setImpersonationId] = useState(null);
    const [canView, setCanView] = useState(false);
    const [canManage, setCanManage] = useState(false);
    const [canManageSite, setCanManageSite] = useState(false);
    const [canViewAndTakeActions, setCanViewAndTakeActions] = useState(false);

    useEffect(() => {
        Api.permissionsApi.get().then((result) => {
            setIsImpersonation(result.isImpersonation);
            setImpersonationId(result.impersonationId);
            setCanView(result.canView);
            setCanManage(result.canManage);
            setCanManageSite(result.canManageSite);
            setCanViewAndTakeActions(result.canViewAndTakeActions);
        });
    }, []);

    const values = {
        isImpersonation,
        impersonationId,
        canView,
        canManage,
        canManageSite,
        canViewAndTakeActions
    };

    return(
        <PermissionsContext.Provider value={values}>
            {props.children}
        </PermissionsContext.Provider>
    );

}