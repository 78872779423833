import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import './accordion.scss';
import Icon, { library } from 'components/Icons/Icon';
import { classNames } from 'utils/util';
import { faChevronDown as faLightChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp as faLightChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';

library.add(faLightChevronDown, faLightChevronUp);

interface AccordionProps {
	id: string;
	content: ReactNode;
	title: string | ReactNode;
	className?: string;
	expanded?: boolean;
	onToggle?: (isOpen?: boolean) => void;
}

const Accordion: React.FC<AccordionProps> = ({ id, content, title, className, expanded, onToggle }) => {
	const [isOpen, setIsOpen] = useState(expanded ?? false);
	const [height, setHeight] = useState('0px');
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [isTransitioning, setIsTransitioning] = useState(false);

	const accordionId = useMemo(() => id + 'lb', [id]);
	const accordionContentId = useMemo(() => id + 'dt', [id]);

	const classes = classNames(['ph-accordion', className]);

	const showContent = () => {
		if (isOpen) {
			setIsTransitioning(true);
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}

		if (onToggle) onToggle(!isOpen);
	};

	const handleTransitionEnd = () => {
		if (!isOpen) {
			setIsTransitioning(false);
		}
	};

	useEffect(() => {
		setHeight(isOpen ? `${contentRef.current?.scrollHeight}px` : '0px');
	}, [isOpen]);

	useEffect(() => {
		setIsOpen(expanded ?? false);
	}, [expanded]);

	return (
		<div className={classes}>
			<h4 className="ph-accordion-header">
				<button
					id={accordionId}
					onClick={showContent}
					aria-controls={accordionContentId}
					aria-expanded={isOpen ? 'true' : 'false'}
				>
					<Icon icon={isOpen ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']} />
					{title}
				</button>
			</h4>
			{(isOpen || isTransitioning) && (
				<div
					ref={contentRef}
					onTransitionEnd={handleTransitionEnd}
					style={{ maxHeight: height }}
					className={`ph-accordion-content ${isOpen ? 'open' : ''}`}
					aria-labelledby={accordionId}
					role="region"
					id={accordionContentId}
				>
					{content}
				</div>
			)}
		</div>
	);
};

export default Accordion;
