import usePermission from 'components/useAuthentication/usePermission';

const withPermission = (Component: React.ElementType, permissionRefName: string, baseUrl: string | null) => {
	const Wrapper = (props: object) => {
		const { isLoading, hasPermission } = usePermission(permissionRefName, baseUrl);

		return <Component isPermissionLoading={isLoading} hasPermission={hasPermission} {...props} />;
	};

	return Wrapper;
};

export default withPermission;
