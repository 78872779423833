import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SimpleLabel from '../shared/SimpleLabel/SimpleLabel';
import SimpleInput from '../shared/SimpleInput/SimpleInput';
import SimpleTextArea from '../shared/SimpleTextArea/SimpleTextArea';
import * as Api from '../../api';
import { library } from '@practicehub/assets';
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
library.add(fasSpinner);
export class SendEmailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            message: '',
            loading: false
        };
    }

    send() {
        this.setState({ loading: true });
        Api.linkedRecordsApi.sendEmail(this.props.linkedRecordId, {
            subject: this.state.subject,
            message: this.state.message
        }).then(() => {
            this.props.onClose(true);
            this.setState({ loading: false });
        });
    }

    render() {

        const {
            loading,
            subject,
            message
        } = this.state;

        return <Modal
            size="lg"
            show={true}
            onHide={() => this.props.closeSendEmailModal(false)}
            centered
        >
            <Modal.Header>
                <Modal.Title>Send Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {loading &&
                    <h3 className="text-center font-weight-bold">
                        <i className="fas fa-spinner fa-spin"></i> Loading
                    </h3>
                }

                {!loading &&
                    <>
                        <br />
                        <div className="row">
                            <div className="col-12">
                                <SimpleLabel title="To" value={`${this.props.toName} (${this.props.to})`} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <SimpleLabel title="From" value={`${this.props.fromName} (${this.props.from})`} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <SimpleInput title="Subject" value={subject} onChange={(evt) => this.setState({ subject: evt.target.value })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <SimpleTextArea title="Message" value={message} onChange={(evt) => this.setState({ message: evt.target.value })} />
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.props.onClose(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => this.send()}>
                    Send
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}
