import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { PermissionsContext } from '../../PermissionsContext';

const RegistrationDetailDropdown = ({ provisioningStatus, registrationRecords, openRegistrationEditor, deactivateRecord, synchroniseAhpraData, showSendEmailModal }) => {
    const { canViewAndTakeActions, canManage } = useContext(PermissionsContext);

    return (<React.Fragment>
        <div className='col-3 pr-3' style={{ float: 'right' }}>
            <div className='row'>
                <div className='col-md-12 mt-2 text-right'>
                    {(canViewAndTakeActions && (provisioningStatus === 'Active' || provisioningStatus === 'New')) &&
                        <Dropdown drop="down end">
                            <Dropdown.Toggle variant="primary" id="manageId">Manage</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => openRegistrationEditor()}>
                                    Add/Remove Registration
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => showSendEmailModal()}>
                                    Send Email
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => synchroniseAhpraData()}>
                                    Synchronise Ahpra Alerts data
                                </Dropdown.Item>
                                {canManage &&
                                    <>
                                    <Dropdown.Item onClick={() => deactivateRecord()}>De-activate</Dropdown.Item>
                                    </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default RegistrationDetailDropdown;