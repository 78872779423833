import React, { useContext } from 'react';
import {useParams} from 'react-router-dom';
import {RecordType} from '../../Constants';
import { RegistrationDetail } from '../RegistrationDetail/RegistrationDetail';
import { PermissionsContext } from '../../PermissionsContext';

const RegistrationDetailRoute = () => {
    const { id } = useParams();
    const { canViewAndTakeActions, canManage , canManageSite} = useContext(PermissionsContext);

    return <RegistrationDetail linkedRecordId={id} canViewAndTakeActions={canViewAndTakeActions} canManage={canManage} canManageSite={canManageSite} />
};

export default RegistrationDetailRoute;
