import './emptyNotifications.scss';
import Icon, { library } from 'components/Icons/Icon';
import { faBell as faLightBell } from '@fortawesome/pro-light-svg-icons/faBell';
library.add(faLightBell);

const EmptyNotifications: React.FC = () => {
	return (
		<div className="ph-empty-notification">
			<Icon icon={['fal', 'bell']} />
			<h4>No notifications right now</h4>
			<p>Check again for updates about manuals, messages and documents.</p>
		</div>
	);
};

export default EmptyNotifications;
