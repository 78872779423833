import React from 'react';
import {Link} from 'react-router-dom';
import {convertHtmlStringToString, convertUtcDateToLocalCustom} from '../../formatters';
import {ActionTypes} from '../../Constants';

const translateLineItemByType = (type, item) => {
    switch(type) {
        case "FileNotes": return translateLineItemNote(item);
        default: return translateLineItem(item);
    }
}

const translateLineItem = (item) => {
    return (
        <>
            Changed <strong className="font-weight-bold">{item.propertyName}</strong> from <strong className="font-weight-bold">{item.oldValue}</strong> to <strong className="font-weight-bold">{item.newValue}</strong>
        </>
    )

}

const translateLineItemNote = (item) => {
    return (
        <>
            Updated file note from <strong className="font-weight-bold">{item.oldValue}</strong> to <strong className="font-weight-bold">{item.newValue}</strong>
        </>
    )

}

const HistoryItemRow = ({key, auditEntry, clickEvent}) => <tr>
    <td>
        {auditEntry.ahpraRegistrationId && auditEntry.ahpraRegistrationId > 0 &&
            <Link to="#" onClick={clickEvent} className="breadcrumb-item">{convertUtcDateToLocalCustom(auditEntry.createdDate, 'DD-MM-YYYY HH:mm:ss')}</Link>
        }
        {auditEntry.ahpraRegistrationId && auditEntry.ahpraRegistrationId <= 0 &&
            convertUtcDateToLocalCustom(auditEntry.createdDate, 'DD-MM-YYYY HH:mm:ss')
        }
    </td>
    <td>{auditEntry.serviceType}</td>
    <td>{auditEntry.createdUser}</td>
    <td>{auditEntry.createdUserRole}</td>
    <td>{convertHtmlStringToString(auditEntry.changes)}</td>
</tr>;

export default HistoryItemRow;