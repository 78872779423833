import React from 'react';
import { classNames } from 'utils/util';
import Icon, { library } from 'components/Icons/Icon';
import { timeAgo } from 'utils/dateUtil';
import './appHeaderNotificationContentItem.scss';
import Skeleton from 'components/Skeleton/Skeleton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBook as faLightBook } from '@fortawesome/pro-light-svg-icons/faBook';
library.add(faLightBook);

export type CustomNotificationItemType = {
	[key: string]: string | boolean | IconProp | Date | undefined;
};

export interface AppHeaderNotificationItem extends CustomNotificationItemType {
	id: string;
	absoluteUrl: string;
	appIcon: IconProp;
	title: string;
	action: string;
	subject: string;
	message: string;
	isRead: boolean;
	notifiedOnUtc: Date;
}

const EmptyAppHeaderNotificationItem: AppHeaderNotificationItem = {
	id: '',
	absoluteUrl: '',
	appIcon: '' as IconProp,
	title: '',
	action: '',
	subject: '',
	message: '',
	isRead: false,
	notifiedOnUtc: new Date(),
};

// We have 3 sections, and for each we are going to show 2 rows, in total 6 rows so that it covers the max height of the notification component
export const EmptyAppHeaderNotifications: AppHeaderNotificationItem[] = Array(2).fill(EmptyAppHeaderNotificationItem);

interface AppHeaderNotificationContentItemProps {
	item: AppHeaderNotificationItem;
	updateNotifications: (notificationIds: string[]) => void;
	isLoading: boolean;
}

const AppHeaderNotificationContentItem: React.FC<AppHeaderNotificationContentItemProps> = ({
	item,
	updateNotifications,
	isLoading,
}) => {
	const classes = classNames([
		'ph-app-header-notification-content-item',
		{ unread: !item.isRead, isLoading: isLoading },
	]);

	return (
		<li className={classes} onClick={() => updateNotifications([item.id])}>
			<a href={item.absoluteUrl}>
				{isLoading ? (
					<Skeleton variant="circular" width={32} height={32} />
				) : (
					<div className="notificationIcon">
						<Icon icon={item.appIcon} />
					</div>
				)}

				<div className="notificationContent">
					<div>
						{isLoading ? (
							<Skeleton height={10} width={180} />
						) : (
							<>
								<b>{item.title}</b> {item.action} <b>{item.subject}</b>
							</>
						)}
					</div>
					<p>{isLoading ? <Skeleton height={10} width={220} /> : item.message}</p>
					<span>{isLoading ? <Skeleton height={8} width={80} /> : timeAgo(item.notifiedOnUtc)}</span>
				</div>
			</a>
		</li>
	);
};

export default AppHeaderNotificationContentItem;
