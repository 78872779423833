import React, { useState, useEffect } from 'react';
import './menuItem.scss';
import { classNames } from 'utils/util';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Menu from 'components/Menu/Menu';
import Icon, { library } from 'components/Icons/Icon';
import { MenuItem as MI } from 'components/useMenuItems/utils/Interfaces';
import Skeleton from 'components/Skeleton/Skeleton';
import { faTachometerAlt as faLightTachometerAlt } from '@fortawesome/pro-light-svg-icons/faTachometerAlt';
import { faBook as faLightBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faBooks as faLightBooks } from '@fortawesome/pro-light-svg-icons/faBooks';
import { faTasks as faLightTasks } from '@fortawesome/pro-light-svg-icons/faTasks';
import { faCommentAlt as faLightCommentAlt } from '@fortawesome/pro-light-svg-icons/faCommentAlt';
import { faCabinetFiling as faLightCabinetFiling } from '@fortawesome/pro-light-svg-icons/faCabinetFiling';
import { faMemoPad as faLightMemoPad } from '@fortawesome/pro-light-svg-icons/faMemoPad';
import { faTools as faLightTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faHandshake as faLightHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faShieldAlt as faLightShieldAlt } from '@fortawesome/pro-light-svg-icons/faShieldAlt';
import { faFileCertificate as faLightFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate';
import { faFileUser as faLightFileUser } from '@fortawesome/pro-light-svg-icons/faFileUser';
import { faUserGraduate as faLightUserGraduate } from '@fortawesome/pro-light-svg-icons/faUserGraduate';
import { faFile as faLightFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faShieldCheck as faLightShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faCog as faLightCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faBriefcase as faLightBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faShoppingCart as faLightShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import { faChevronDown as faLightChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faExternalLinkAlt as faLightExternalLinkAlt } from '@fortawesome/pro-light-svg-icons/faExternalLinkAlt';
import { faAward as faLightAward } from '@fortawesome/pro-light-svg-icons/faAward';
import { faPuzzlePiece as faLightPuzzlePiece } from '@fortawesome/pro-light-svg-icons/faPuzzlePiece';
import { faUsersCrown as faLightUsersCrown } from '@fortawesome/pro-light-svg-icons/faUsersCrown';
import { faListCheck as falListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck';

library.add(
	faLightTachometerAlt,
	faLightBook,
	faLightBooks,
	faLightTasks,
	faLightCommentAlt,
	faLightCabinetFiling,
	faLightMemoPad,
	faLightTools,
	faLightHandshake,
	faLightShieldAlt,
	faLightFileCertificate,
	faLightFileUser,
	faLightUserGraduate,
	faLightFile,
	faLightShieldCheck,
	faLightCog,
	faLightBriefcase,
	faLightShoppingCart,
	faLightChevronDown,
	faLightExternalLinkAlt,
	faLightAward,
	faLightPuzzlePiece,
	faLightUsersCrown,
	falListCheck
);

interface MenuItemProps {
	label: string;
	icon?: IconProp;
	items?: Array<MI>;
	isActive?: boolean;
	isExpandedByDefault?: boolean;
	href?: string | null;
	isLoading: boolean;
	isExternal?: boolean;
	supplementaryImage?: IconProp;
	isChild?: boolean;
}

const EmptyMenuItem: MenuItemProps = {
	label: '',
	icon: '' as IconProp,
	items: [] as Array<MI>,
	isActive: false,
	isExpandedByDefault: false,
	href: '#',
	isLoading: false,
};

export const EmptyMenuItems: MI[] = Array(10).fill(EmptyMenuItem);

const MenuItem: React.FC<MenuItemProps> = ({
	label,
	icon,
	items,
	isActive,
	isExpandedByDefault,
	href,
	isLoading,
	isExternal,
	supplementaryImage,
	isChild,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		if (isExpandedByDefault) setIsExpanded(true);
	}, [isExpandedByDefault]);

	const onExpandHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		setIsExpanded(!isExpanded);
	};

	const classes = classNames([
		'ph-menuItem',
		{
			hasChildren: !!items,
			isExpanded: !!items && isExpanded,
			isActive: isActive && !items,
		},
	]);

	const iconClasses = classNames([
		'ph-menuItem-chevron',
		{
			isExpanded: isExpanded,
			isCollapsed: !isExpanded,
		},
	]);

	return (
		<li className={classes}>
			<a
				href={href ?? '#'}
				onClick={href ? undefined : onExpandHandler}
				className="ph-menuItem-item"
				target={isExternal ? '_blank' : '_self'}
				rel="noreferrer"
			>
				{isLoading ? (
					<Skeleton variant="circular" width={24} height={24} />
				) : (
					!isChild && icon && <Icon icon={icon} title={label} className="ph-menuItem-icon" />
				)}
				{isLoading ? <Skeleton height={10} width={100} /> : label}
				{!isLoading && items && items.length > 0 && (
					<Icon icon={['fal', 'chevron-down']} title="Manual" className={iconClasses} />
				)}
				{!isLoading && isExternal && (
					<Icon icon={supplementaryImage} title="Manual" className="ph-menuItem-external" />
				)}
			</a>

			{!!items && isExpanded && <Menu items={items} isLoading={!items} className="ph-subMenu" isChildMenu />}
		</li>
	);
};

export default MenuItem;
