import React, { useState, useRef } from 'react';
import './FileUpload.scss';
import { classNames } from 'utils/util';
import Icon, { library } from 'components/Icons/Icon';
import ErrorMessage from 'components/ErrorMessage';
import FormLabel from 'components/FormLabel';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import Alert from 'components/Alert';
import { faCloudArrowUp as faLightCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp';
import { faTimes as faLightTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
library.add(faLightCloudArrowUp, faLightTimes);

interface FileProp {
	id?: number;
	name: string;
	size?: number;
	type?: string;
	webkitRelativePath?: string;
	lastModifiedDate?: Date;
	lastModified?: number;
}

interface DocumentProps {
	documentTitle?: string;
	url?: string;
	fileType?: string;
	canDownload?: boolean;
	customDownload?: {
		fn?: (...args: any[]) => void;
		params?: any;
	};
}

interface PreviewDocumentProps {
	document: DocumentProps;
}

interface FileUploadProps {
	id: string;
	label?: string;
	className?: string;
	multiple?: boolean;
	isMandatory?: boolean;
	errorMessage?: string;
	fileList?: Array<FileProp>;
	caption?: string | null;
	onFileChange?: (files: Array<FileProp>) => void;
	accept?: Array<string>;
	disabled?: boolean;
	viewOnly?: boolean;
	showFileListLabel?: boolean;
	fileListLabel?: string;
	previewFileComponent?: {
		component: React.FC<PreviewDocumentProps>;
		classes: string;
		documentResolver: (file: FileProp) => DocumentProps;
	};
	confirmOnRemove?: boolean;
	confirmRemoveTitle?: string;
	showRemoveAlert?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
	id,
	label,
	className,
	multiple = false,
	isMandatory = false,
	errorMessage,
	fileList = [],
	caption,
	onFileChange,
	accept,
	disabled = false,
	viewOnly = false,
	showFileListLabel = false,
	fileListLabel = 'Files Uploaded',
	previewFileComponent = null,
	confirmOnRemove = false,
	confirmRemoveTitle = 'Delete Attachment',
	showRemoveAlert = true,
}) => {
	const classes = classNames([
		'ph-file-upload',
		{ hasNoLabel: !label, isDisabled: disabled, hasError: !!errorMessage },
		className,
	]);
	const [updatedFileList, setUpdatedFileList] = useState<FileProp[]>(fileList);
	const [deletedFile, setDeletedFile] = useState<FileProp>();
	const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);

	const wrapperRef = useRef<HTMLDivElement>(null);
	const onDragEnter = () => {
		if (!wrapperRef || !wrapperRef.current) return;

		wrapperRef.current.classList.add('dragover');
	};

	const onDragLeave = () => {
		if (!wrapperRef || !wrapperRef.current) return;

		wrapperRef.current.classList.remove('dragover');
	};

	const onDrop = () => {
		onDragLeave();
	};

	const onFileDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;

		const newFile = e.target.files[0];
		if (!newFile) return;

		const updatedList = multiple ? [...updatedFileList, ...e.target.files] : [newFile];
		setUpdatedFileList(updatedList);
		if (onFileChange) onFileChange(updatedList);
	};

	const removeFile = (item?: FileProp) => {
		if (item) {
			const updatedList = [...updatedFileList];
			updatedList.splice(updatedFileList.indexOf(item), 1);
			setUpdatedFileList(updatedList);
			if (onFileChange) onFileChange(updatedList);
			if (confirmOnRemove) onCloseConfirmRemoveModal();
		}
	};

	const onRemoveHandler = (item: FileProp) => {
		if (confirmOnRemove) {
			setShowConfirmRemoveModal(true);
			setDeletedFile(item);
		} else {
			removeFile(item);
		}
	};

	const onCloseConfirmRemoveModal = () => {
		setShowConfirmRemoveModal(false);
		setDeletedFile(undefined);
	};

	const downloadFileBlob = (file: File) => {
		const a = document.createElement('a');
		const url = URL.createObjectURL(file);
		a.href = url;
		a.download = file.name;
		a.click();
		URL.revokeObjectURL(url);
	};

	return (
		<div className={classes}>
			{label ? <FormLabel label={label} htmlIdFor={id} isMandatory={isMandatory} /> : null}
			<div
				ref={wrapperRef}
				className="ph-file-upload-drag-and-drop"
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				{!viewOnly && (
					<>
						<input
							id={id}
							type="file"
							multiple={multiple}
							className={'ph-file-upload-input-file'}
							accept={accept ? accept.join(', ') : undefined}
							disabled={disabled}
							onChange={onFileDrop}
						/>
						<label htmlFor={id}>
							<Icon icon={['fal', 'cloud-arrow-up']} className="ph-file-upload-icon" />
							<p>
								<u>Click to upload</u> or drag and drop {multiple ? 'files' : 'file'}
							</p>
							{caption && <p className={'ph-file-upload-caption'}>{caption}</p>}
						</label>
					</>
				)}
			</div>
			{errorMessage && <ErrorMessage error={errorMessage} />}
			{!!updatedFileList?.length && showFileListLabel && fileListLabel && <p>{fileListLabel}</p>}
			{updatedFileList &&
				updatedFileList.constructor === Array &&
				updatedFileList.map((item) => {
					const PreviewFileComponent = previewFileComponent?.component;
					const documentResolver = previewFileComponent?.documentResolver;
					const document = documentResolver ? documentResolver(item) : null;

					return (
						<div
							key={`${item?.id ?? item?.name ?? ''}${new Date().getTime()}`}
							className={'ph-file-upload-display-file'}
						>
							<p title={item?.name}>{item?.name}</p>
							<div className={`ph-file-upload-actions ${previewFileComponent?.classes || ''}`}>
								{PreviewFileComponent && document && (
									<PreviewFileComponent
										document={{
											documentTitle: item?.name,
											fileType: item?.name?.split('.').pop(),
											...document,
											customDownload: document.customDownload ?? {
												fn: item instanceof File ? () => downloadFileBlob(item) : undefined,
											},
											url: item instanceof File ? URL.createObjectURL(item) : document.url,
										}}
									/>
								)}
								{!viewOnly && (
									<IconButton
										icon={['fal', 'times']}
										onClick={() => onRemoveHandler(item)}
										title="Delete"
										className="deleteAttachment"
									/>
								)}
							</div>
						</div>
					);
				})}
			<Modal
				show={showConfirmRemoveModal}
				buttonSubmitText="Delete"
				onCancel={onCloseConfirmRemoveModal}
				onClose={onCloseConfirmRemoveModal}
				onSubmit={() => removeFile(deletedFile)}
				size="small"
				title={confirmRemoveTitle}
				className="ph-file-delete-modal"
			>
				{showRemoveAlert && (
					<Alert state="isDanger" message="This action cannot be undone." showCloseButton={false} />
				)}
				<p>
					Are you sure you want to delete <b>{deletedFile?.name}</b> ?
				</p>
			</Modal>
		</div>
	);
};

export default FileUpload;
