import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './Settings.scss';
import Heading from '../shared/Heading/Heading';
import { SettingType, SuccessMessage } from '../../Constants';
import Setting from './Setting';
import { GlobalAlertUtility } from '../../utils/GlobalAlertUtility';
import * as Api from '../../api';
import { library } from '@practicehub/assets';
import { faSpinner as faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
library.add(faSpinner);

export class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            settings: {},
            errors: {}
        };
    }

    componentDidMount() {
        document.title = 'Ahpra Alerts - Settings';
        this.getOrganisationSettings();
    }

    getOrganisationSettings() {
        Api.organisationSettingsApi.get().then(result => {
            this.setState({
                isLoading: false,
                settings: result.reduce((acc, curr) => {
                    acc[curr.settingType] = curr.value;
                    return acc;
                }, {})
            });
        });
    }

    updateSetting(type, value) {

        const settings = { ...this.state.settings };

        delete settings[type];

        settings[type] = value;

        this.setState({ settings });

    }

    validate() {

        let isValid = true;
        const errors = {};

        const {
            settings,
        } = this.state;

        if (!settings[SettingType.DAYS_PRIOR_TO_EXPIRY_UPCOMING_ALERT]) {
            errors[SettingType.DAYS_PRIOR_TO_EXPIRY_UPCOMING_ALERT] = "Please enter a valid number of days";
            isValid = false;
        }

        this.setState({ errors });

        return isValid;

    }

    save() {

        if (!this.validate()) {
            return;
        }

        const { settings } = this.state;

        this.setState({ isLoading: true });

        var e = Object.keys(settings).reduce((acc, curr) => {
            acc.push({ settingType: curr, value: settings[curr] });
            return acc;
        }, []);
        Api.organisationSettingsApi.updateOrganisationSettings(Object.keys(settings).reduce((acc, curr) => {
            acc.push({ settingType: curr, value: settings[curr] });
            return acc;
        }, [])
        ).then((result) => {
            GlobalAlertUtility.showSuccess(SuccessMessage.SETTINGS_SAVE);
            this.setState({
                settings: result.reduce((acc, curr) => {
                    acc[curr.settingType] = curr.value;
                    return acc;
                }, {}), isLoading: false
            });
        });

    }

    render() {

        const { settings, isLoading, errors } = this.state;

        return <div className="Dashboard">
            <Heading
                colClasses='col-9'
                title="Organisation Settings"
                titleElement={
                    <h3 title="Organisation Settings" className='overflow-inner'>
                        <span className='page-title-inner'>Organisation Settings</span>
                    </h3>
                }
                breadcrumbs={
                    <div className='col-md-12'>
                        <ol className='breadcrumb float-left'>
                            <li className='breadcrumb-item'>
                                <Link to='/'>Ahpra Alerts</Link>
                            </li>
                            <li className='breadcrumb-item active'>
                                <Link to='/settings'>Organisation Settings</Link>
                            </li>
                        </ol>
                    </div>
                }
            />

            <div className='col-3 pr-3' style={{ float: 'right' }}>
                <div className='row'>
                    <div className='col-md-12 mt-2 text-right'>
                        <Dropdown drop="down end">
                            <Dropdown.Toggle variant="primary" id="manageId">
                                Manage
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.save()}>
                                    Save
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div style={{ clear: 'both' }} />

            {isLoading &&
                <div className="text-center pt-4 pb-4">
                    <div className="fa fa-spinner fa-spin display-4"></div>
                </div>
            }
            {!isLoading &&
                <div className="row scrollable-content">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">Settings</div>
                            <div className="card-body">
                                {Object.keys(SettingType).map((key, i) =>
                                    <Setting
                                        key={i}
                                        type={SettingType[key]}
                                        value={settings[SettingType[key]]}
                                        error={errors[SettingType[key]]}
                                        onChange={(type, value) => this.updateSetting(type, value)} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
}
