import React from 'react';
import './tsmBanner.scss';
import { faExclamationCircle as faLightExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import Icon, { library } from 'components/Icons/Icon';
library.add(faLightExclamationCircle);

export interface TsmBannerProps {
	showBanner: boolean;
	impersonationId: string;
	impersonationFirstName: string;
	impersonationLastName: string;
	orgName: string;
	onTsmLogout: () => void;
}

const EMPTY_IMPERSONATION_ID = '00000000-0000-0000-0000-000000000000';

const textResources = {
	temporarilyLoggedIn: 'You are temporarily logged in to',
	as: ' as ',
	whenDone: '. When done, please ',
	logOut: 'log out',
	ofThisOrganisation: ' of this organisation.',
};

const TsmBanner: React.FC<TsmBannerProps> = ({
	showBanner,
	impersonationId,
	impersonationFirstName,
	impersonationLastName,
	orgName,
	onTsmLogout,
}) => {
	if (!showBanner) {
		return null;
	}

	const name =
		impersonationFirstName || impersonationLastName
			? `${impersonationFirstName} ${impersonationLastName}`.trim()
			: '';

	return (
		<div className="ph-tsm-banner">
			<Icon icon={['fal', 'exclamation-circle']} className="ph-tsm-banner-icon" />
			<span>
				{textResources.temporarilyLoggedIn} <strong>{orgName}</strong>
				{name && impersonationId != EMPTY_IMPERSONATION_ID && (
					<>
						{textResources.as}
						<strong>{name}</strong>
					</>
				)}
				{textResources.whenDone}
				<a href="#" onClick={onTsmLogout}>
					{textResources.logOut}
				</a>
				{textResources.ofThisOrganisation}
			</span>
		</div>
	);
};

export default TsmBanner;
