import React from 'react';
import './icons.scss';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from 'utils/util';
import { PhIconProp } from './FontAwesomeCustomIcons';
import { faExclamationTriangle as faLightExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
library.add(faLightExclamationTriangle);

export { library };

export interface IconProps {
	icon?: IconProp | PhIconProp;
	title?: string;
	className?: string;
	onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const Icon: React.FC<IconProps> = ({
	icon = ['fal', 'triangle-exclamation'],
	className = '',
	title = '',
	...props
}) => {
	return (
		<div className={classNames(['ph-icon', className])}>
			<FontAwesomeIcon icon={icon as IconProp} title={title} {...props} />
		</div>
	);
};

export default Icon;
