import React, { useState, useEffect } from 'react';
import { useMenuContext, ErrorPage, library } from '@practicehub/assets';
import { faFaceHandPeeking } from '@fortawesome/pro-thin-svg-icons/faFaceHandPeeking';
library.add(faFaceHandPeeking);

const Unauthorised = () => {
    const { menuItems } = useMenuContext();
    const [dashboardUrl, setDashboardUrl] = useState('');

    useEffect(() => {
        if (menuItems?.length) {
            const dashboardItem = menuItems.find((s) => s.label?.toUpperCase() === 'DASHBOARD');

            if (dashboardItem?.defaultLaunchUrl) {
                setDashboardUrl(dashboardItem.defaultLaunchUrl);
            }
        }
    }, [menuItems]);

    useEffect(() => {
        document.title = 'Ahpra - Unauthorised';
    }, []);

    return <ErrorPage
        className='unauthorised no-permission'
        iconClass={['fat', 'face-hand-peeking']}
        strongContent="You don't have permission to view this Page"
        content='To request access, contact your Administrator.'
        link={dashboardUrl || '#'}
        linkText='Go to the Dashboard'
    />;
};

export default Unauthorised;