import React from 'react';
import './checkbox.scss';
import Icon, { library } from 'components/Icons/Icon';
import { classNames } from 'utils/util';
import { ComponentBase } from 'utils/types';
import { faCheckSquare as faSolidCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faSquare as faRegularSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
library.add(faSolidCheckSquare, faRegularSquare);

export interface CheckboxProps extends ComponentBase {
	id: string;
	label: string;
	disabled?: boolean;
	checked?: boolean;
	handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	name?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ id, label, disabled, handleCheckboxChange, checked, className, name }) => {
	const isChecked = checked ?? false;

	function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
		handleCheckboxChange?.(e);
	}

	return (
		<div className={classNames(['ph-checkbox-v2', className])}>
			<input
				id={id}
				name={name}
				type="checkbox"
				onChange={handleOnChange}
				disabled={disabled}
				checked={isChecked}
			/>

			<label htmlFor={id}>
				<Icon icon={isChecked ? ['fas', 'check-square'] : ['far', 'square']} />
				{label}
			</label>
		</div>
	);
};

export default Checkbox;
