import React from 'react';
import Checkbox from 'components/Checkbox';
import ItemCount from './ItemCount';
import Button from 'components/Button';
import Icon, { library } from 'components/Icons/Icon';
import { faSpinner as faSolidSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faTimes as faLightTimes } from '@fortawesome/pro-light-svg-icons';
import './checkboxgroup.scss';
library.add(faSolidSpinner, faLightTimes);

export interface CheckboxInterface {
	id: string;
	name: string;
	checked?: boolean;
	disabled?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	itemCount?: number;
	noAccess?: boolean;
}

interface CheckboxGroupProps {
	label?: string;
	checkboxes: CheckboxInterface[];
	isLoading?: boolean;
	showClear?: boolean;
	onClearClick?: () => void;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
	label = '',
	checkboxes = [],
	isLoading,
	showClear,
	onClearClick,
}) => {
	const handleClear = () => {
		if (onClearClick) {
			onClearClick();
		}
	};

	const renderCheckboxes = () =>
		checkboxes.map((checkbox) => (
			<div key={checkbox.id} className="ph-checkboxGroup-elements">
				<Checkbox
					id={checkbox.id}
					label={checkbox.name}
					checked={checkbox.checked}
					disabled={checkbox.disabled}
					handleCheckboxChange={checkbox.onChange}
				/>
				{isLoading ? (
					<Icon icon={['fas', 'spinner']} className="ph-loading-icon" />
				) : (
					<ItemCount {...checkbox} />
				)}
			</div>
		));

	return (
		<fieldset className="ph-checkboxGroup">
			{!!label && (
				<legend>
					{label}
					{showClear && checkboxes.length > 0 && (
						<Button
							type="secondary"
							leftIcon={['fal', 'times']}
							size="medium"
							onClick={handleClear}
							buttonText="Clear"
						/>
					)}
				</legend>
			)}
			{renderCheckboxes()}
		</fieldset>
	);
};

export default CheckboxGroup;
