import { NotificationsUtility } from './NotificationsUtility';

export class ApiUtility {
    static getBaseUrl() {
        return '';
    }

    static errorResponse(error) {
        let status = error?.response?.status

        switch (status) {
            case 400:
                NotificationsUtility.showError(error.message);
                break;
            case 401:
                window.location.href = '/unauthorised';
                break;
        }

        return error;
    };
}
 