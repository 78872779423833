import React, {Component} from 'react';
import Alert from './Alert';
import {AlertFileNoteEditor} from './AlertFileNoteEditor';
import {AlertStatus, AlertType} from '../../Constants';
import * as Api from '../../api';

export class Alerts extends Component {

    state = {
        showModal: false,
        modalTitle: '',
        modalActionDescription: '',
        modalActionText: '',
        requireNote: false,
        currentOnSave: null,
        currentAlert: null
    }

    acknowledgeAlert = (alert) => {

        const onSave = (note) => {
            const {
                loadingEvent
            } = this.props;
            if (loadingEvent) loadingEvent(true);
            Api.alertsApi.acknowledgeAlert({
                ahpraRegistrationNumber: alert.ahpraRegistrationNumber, 
                linkedRecordId: alert.linkedRecordId,
                registrationType: alert.registrationType,
                alertType: alert.type,
                fileNote: note
            }).then(result => {
                if(loadingEvent) loadingEvent(false);
                this.onModalClose();
                this.props.reload();
            }).catch(() => {
                if(loadingEvent) loadingEvent(false);
            });
        }

        this.setState({
            currentAlert: alert,
            modalTitle: `Acknowledge`,
            modalActionDescription: 'Please submit a note to acknowledge the alert.',
            modalActionText: 'Acknowledge',
            requireNote: false,
            currentOnSave: onSave,
            showModal: true
        });
    }

    closeAlert = (alert) => {
        const onSave = (note) => {
            const {
                loadingEvent
            } = this.props;
            if(loadingEvent) loadingEvent(true);
            Api.alertsApi.closeAlert({
                ahpraRegistrationNumber: alert.ahpraRegistrationNumber, 
                linkedRecordId: alert.linkedRecordId,
                registrationType: alert.registrationType,
                alertType: alert.type,
                fileNote: note
            }).then(result => {
                if(loadingEvent) loadingEvent(false);
                this.onModalClose();
                this.props.reload();
            }).catch(() => {
                if(loadingEvent) loadingEvent(false);
            });
        }

        //Card#5694 Update wording in 'User Added' alert to remind admin to confirm name in PracticeHub matches data pulled from Ahpra
        const modalText = alert.type == AlertType.ADDED_USER && (alert.status == AlertStatus.NEW || alert.status == AlertStatus.OPEN) ? 'Please ensure the Registered Name and other AHPRA registration details are correct for the person in this record. You may add \'ID verified\' in the Note field below as confirmation when closing the alert.' : 'Please submit a note to acknowledge and close the alert.';

        this.setState({
            currentAlert: alert,
            modalTitle: `Close`,
            modalActionDescription: modalText,
            modalActionText: 'Close',
            requireNote: true,
            currentOnSave: onSave,
            showModal: true
        });
    }

    onModalClose = () => {
        this.setState({showModal: false, modalTitle: '', modalActionDescription: '', modalActionText: ''});
    }

    render() {
        const alerts = this.props.alerts;
        const isEmptyAhpraRecord = this.props.isEmptyAhpraRecord;
        const openRegistrationEditorAndCloseAlert = this.props.openRegistrationEditorAndCloseAlert;

        if(!alerts || alerts.length === 0 || !this.props.canViewAndTakeActions) return null;
        return <div className="row">
            <div className="col-12">
                {alerts.map((a, i) => <Alert key={i} alert={a} acknowledgeAlert={this.acknowledgeAlert} closeAlert={this.closeAlert} isEmptyAhpraRecord={isEmptyAhpraRecord} openRegistrationEditorAndCloseAlert={openRegistrationEditorAndCloseAlert} />)}
            </div>
            <AlertFileNoteEditor 
                show={this.state.showModal}
                title={this.state.modalTitle}
                actionDescription={this.state.modalActionDescription}
                actionText={this.state.modalActionText}
                requireNote={this.state.requireNote}
                onSave={this.state.currentOnSave}
                onClose={this.onModalClose}
            />
        </div>;
    }
}