import React, { Component } from 'react';
import './RegistrationListFilters.scss';
import { dynamicSort } from '../../utils/utils';
import { MultiSelect } from '@practicehub/central';
import { SelectList } from '../shared/SelectList/SelectList';
import RegistrationListFilterPractice from './RegistrationListFilterPractice';
import { DefaultProvisioningFilterOptions, DefaultAhpraStatusFilterOptions } from '../../Constants';
import { AlertType, StipulationFilterOptions, FilterAlertStatus } from '../../utils/enums';
import  * as Api from '../../api';

export class RegistrationListFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            professions: [],
            clinicServiceTypes: [],
            practices: [],
            staffs: []
        };
    }

    componentDidMount() {
        this.getUsersAndStaffs([]);
        this.getProfessions();
        this.getClinicServiceTypes();
        this.getPractices();
    }

    getUsersAndStaffs(provisioningStatusFilter) {
        Api.userApi.getAllByProvisioningStatus(provisioningStatusFilter).then((data) => {
            const users = data.map((value) => {
                return {
                    label: value.userGivenName + ' ' + value.userSurname,
                    value: value.userId
                };
            });
            this.setState({ users: users.sort(dynamicSort('label')) });
        });

        Api.staffRecordsApi.getAllByProvisioningStatus(provisioningStatusFilter).then((allStaffs) => {
            const staffs = allStaffs.map((value) => {
                return {
                    label: `${value.firstName} ${value.lastName}`,
                    value: `${value.staffRecordId}`
                }
            });

            this.setState({ staffs: staffs });
        })
    }

    getProfessions() {
        Api.professionApi.get().then((data) => {
            const professions = data.map((value) => {
                return {
                    label: value,
                    value: value
                };
            });
            this.setState({ professions: professions.sort(dynamicSort('label')) });
        });
    }

    getClinicServiceTypes() {
        Api.clinicalServiceTypeApi.get().then((data) => {
            const clinicServiceTypes = data.map((value) => {
                return {
                    label: value.name,
                    value: value.name
                };
            });
            this.setState({ clinicServiceTypes: clinicServiceTypes.sort(dynamicSort('label')) });
        });
    }

    provisionStatusOnChange = (values) => {
        this.props.provisioningFilterChange(values);
        this.getUsersAndStaffs(values.includes('') ? [] : values);
    }

    /**
     * sorting practices, the main org should be listed first, and all the sub orgs in alphabetical order
     * 
     * @param {*} a 
     * @param {*} b 
     */
    sortFilterPractices(a, b) {
        if ((!a.parentId && b.parentId) || (a.parentId && b.parentId && a.name < b.name)) return -1;
        if ((!b.parentId && a.parentId) || (a.parentId && b.parentId && a.name > b.name)) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    getPractices() {
        Api.practiceApi.getAll().then((data) => {
            const practices = data.sort(this.sortFilterPractices).map((value) => {
                return {
                    label: value.name,
                    value: value.id
                };
            });
            this.setState({ practices: practices });
        });
    }

    render() {
        const {
            users,
            staffs
        } = this.state;

        const allUserRecords = [
            ...users,
            ...staffs
        ].sort(dynamicSort('label'));

        return <div className="row">
            <div className="col">
                <div className="RegistrationListFilters card shadow-sm rounded-lg">
                    <div className="card-body" style={{ padding: '25px 25px 25px 25px' }}>
                        <div className='form-row' style={{ paddingBottom: '15px' }}>
                            <div className='col'>
                                <div className='float-right'>
                                    <a className='cursor-pointer text-color-blue' style={{ fontSize: '14px' }} onClick={this.props.resetAllFilters}>
                                        Reset all
                                    </a>
                                </div>
                            </div>
                        </div>

                        <MultiSelect
                            id='userFilter'
                            label='Users'
                            placeholder='Users'
                            enableAllOption={true}
                            values={this.props.selectedUsers}
                            onChange={this.props.userFilterChange}
                            options={[
                                ...allUserRecords
                            ]}
                            showSearch={true}
                        />

                        <MultiSelect
                            id='provisiongFilter'
                            label='Provisioning'
                            placeholder='Provisioning'
                            enableAllOption={true}
                            values={this.props.selectedProvisionings}
                            onChange={this.provisionStatusOnChange}
                            options={DefaultProvisioningFilterOptions}
                        />

                        <MultiSelect
                            id='professionFilter'
                            label='Profession'
                            placeholder='Profession'
                            enableAllOption={true}
                            values={this.props.selectedProfession}
                            onChange={this.props.professionFilterChange}
                            options={[
                                ...this.state.professions
                            ]}
                        />

                        <RegistrationListFilterPractice
                            selectedPractices={this.props.selectedPractices}
                            practiceFilterChange={this.props.practiceFilterChange}
                            practices={this.state.practices}
                        />

                        <MultiSelect
                            id='statusFilter'
                            label='Status'
                            placeholder='Status'
                            enableAllOption={true}
                            values={this.props.selectedStatus}
                            onChange={this.props.statusFilterChange}
                            options={DefaultAhpraStatusFilterOptions}
                        />

                        <MultiSelect
                            id='stipulationsFilter'
                            label='Stipulations'
                            placeholder='Stipulations'
                            enableAllOption={true}
                            values={this.props.selectedStipulations}
                            onChange={this.props.stipulationsFilterChange}
                            options={
                                [
                                    { label: 'With stipulations', value: StipulationFilterOptions.WithStipulations },
                                    { label: 'Without stipulations', value: StipulationFilterOptions.WithoutStipulations }
                                ]
                            }
                        />

                        <MultiSelect
                            id='alertTypeFilter'
                            label='Alert Type'
                            placeholder='Alert Type'
                            enableAllOption={true}
                            values={this.props.selectedAlertType}
                            onChange={this.props.alertTypeFilterChange}
                            options={
                                [
                                    { label: 'Added User', value: AlertType.AddedUser },
                                    { label: 'Cancelled Registrations', value: AlertType.CancelledRegistration },
                                    { label: 'Deactivated User', value: AlertType.DeactivatedUser },
                                    { label: 'Invalid', value: AlertType.InvalidRegistrations },
                                    { label: 'Stipulations', value: AlertType.Stipulations },
                                    { label: 'Suspended Registrations', value: AlertType.SuspendedRegistrations },
                                    { label: 'Upcoming Registrations', value: AlertType.UpcomingRegistrations },
                                ]
                            }
                        />

                        <MultiSelect
                            id='alertStatusFilter'
                            label='Alert Status'
                            placeholder='Alert Status'
                            enableAllOption={true}
                            values={this.props.selectedAlertStatus}
                            onChange={this.props.alertStatusFilterChange}
                            options={
                                [
                                    { label: 'New', value: FilterAlertStatus.New },
                                    { label: 'None', value: FilterAlertStatus.None },
                                    { label: 'Open', value: FilterAlertStatus.Open },
                                    { label: 'Overdue', value: FilterAlertStatus.Overdue }
                                ]
                            }
                        />

                        <SelectList
                            name='dateFilter'
                            label='Expiry Date'
                            options={[
                                { label: 'All', value: -1 },
                                { label: 'Today', value: 0 },
                                { label: 'In 7 days', value: 1 },
                                { label: 'In 14 days', value: 2 },
                                { label: 'Custom date', value: 3 }
                            ]}
                            value={this.props.selectedDate}
                            onChange={this.props.dateFilterChange}
                        />

                        {this.props.selectedDate == 3 && (
                            <React.Fragment>
                                <div className='form-row'>
                                    <div className='form-group col-6'>
                                        <label className='control-label'>Due date from</label>
                                        <input
                                            className='form-control'
                                            autoFocus={true}
                                            type='date'
                                            name='dateFilterFrom'
                                            value={this.props.selectedDateFrom}
                                            onChange={this.props.dateFilterChange}
                                            style={{ fontSize: '14px' }}
                                        />
                                    </div>

                                    <div className='form-group col-6'>
                                        <label className='control-label'>Due date to</label>
                                        <input
                                            className='form-control'
                                            type='date'
                                            name='dateFilterTo'
                                            value={this.props.selectedDateTo}
                                            onChange={this.props.dateFilterChange}
                                            style={{ fontSize: '14px' }}
                                        />
                                    </div>
                                </div>

                                <a href='#' onClick={this.props.updateCustomDateFilter} style={{ fontSize: '14px' }}>
                                    Apply custom date range
                                </a>
                            </React.Fragment>
                        )}

                        <div style={{ textAlign: 'center' }}>
                            <br />
                            <button className='btn btn-primary' onClick={this.props.saveFilters}>
                                Save Selection
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

