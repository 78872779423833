import React, { Component } from 'react';
import './RegistrationSelector.scss';
import { Form } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';
import { ConfirmDialogUtility } from '../../utils/ConfirmDialogUtility';
import { DropdownFilterNoTitle } from '../shared/DropdownFilterNoTitle/DropdownFilterNoTitle';
import ModalAlert from '../ModalAlert/ModalAlert';
import { SuccessMessage, ErrMessage } from '../../Constants';
import * as Api from '../../api';
import { library } from '@practicehub/assets';
import { faPlus as faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faEllipsisV as faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
library.add(faPlus, faEllipsisV, fasSpinner);

export class RegistrationSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownProfessions: [],
            clinicalServiceTypes: [],
            registrationRecords: [],
            showEditor: false,
            selectedProfessions: '',
            registrationNumber: '',
            isChanges: false,
            isLoading: false,
            alertShow: 'hidden',
            alertMessage: '',
            alertMessageType: 'success'
        }
    }

    componentDidMount() {
        this.getClinicalServiceTypes();
        this.getRegistrationRecord();
    }

    getClinicalServiceTypes() {
        
        Api.clinicalServiceTypeApi.get().then((data) => {
            const dropdownFilterItems = data.map(value => {
                return {
                    id: value.name,
                    name: value.name
                };
            });
            this.setState({
                dropdownProfessions: dropdownFilterItems,
                clinicalServiceTypes: data
            });
        });
    }

    getRegistrationRecord() {
        this.setState({ isLoading: true });
        Api.registrationApi.getbyLinkedRecord(this.props.linkedRecordId).then((data) => {
            this.setState({
                isLoading: false,
                registrationRecords: data
            });
        });
    }

    professionChange(value) {
        this.setState({
            selectedProfessions: value.name
        });
    }

    closeAlert() {
        this.setState({
            alertShow: 'hidden'
        });
    }

    showAlertSuccess(messageSuccess) {
        this.setState({
            alertShow: '',
            alertMessage: messageSuccess,
            alertMessageType: 'success'
        });
    }

    showAlertError(messageError) {
        this.setState({
            alertShow: '',
            alertMessage: messageError,
            alertMessageType: 'warning'
        });
    }

    canAddRegistration() {
        return this.state.currentClinicalServiceType != null && this.state.currentClinicalServiceType === this.state.selectedClinicalServiceIType;
    }

    deleteRegistration(registrationRecord) {
        ConfirmDialogUtility.showConfirmDialog(`Are you sure you want to remove the registration number '${registrationRecord.registrationNumber}'? Please provide a reason:`, (result, note) => {
            if (result) {
                this.closeAlert();
                Api.registrationApi.delete(registrationRecord.id, note).then(() => {
                    this.setState({
                        isChanges: true,
                    });
                    this.getRegistrationRecord();
                    this.showAlertSuccess(SuccessMessage.REGISTRATION_REMOVED);
                });
            }
        }, undefined, true);
    }

    newRegistration() {
        this.setState({
            showEditor: true
        });
    }

    cancelAdd() {
        this.setState({
            showEditor: false
        });
    }

    registrationNumberChange(value) {
        this.setState({
            registrationNumber: value.target.value
        });
    }

    close() {
        if (this.state.isChanges) {
            this.props.onCloseWithUpdate();
        } else {
            this.props.onClose();
        }
    }

    save() {
        if (this.state.registrationNumber && this.state.registrationNumber != ''
            && this.state.selectedProfessions && this.state.selectedProfessions != '') {

            this.setState({ isLoading: true });

            this.closeAlert();
            Api.registrationApi.create({
                linkedRecordId: +this.props.linkedRecordId,
                registrationNumber: this.state.registrationNumber,
                profession: this.state.selectedProfessions
            }).catch(err => {
                this.setState({
                    showEditor: false,
                    isChanges: true,
                    isLoading: false
                });
                this.showAlertError(ErrMessage.REGISTRATION_ADDED);
                console.log(err);
            }).then(() => {
                this.setState({
                    showEditor: false,
                    isChanges: true,
                    isLoading: false
                });
                this.getRegistrationRecord();
                this.showAlertSuccess(SuccessMessage.REGISTRATION_ADDED);
            });
        }
    }

    render() {
        const {
            registrationRecords,
            isLoading
        } = this.state;
        return <>
            <Modal
                size="xl"
                show={this.props.show}
                onHide={() => this.close()}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Registration Numbers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row pt-3" onClick={this.toggleExpanded}>
                        <div className="col">
                            <h3 className="card-title">Registrations</h3>
                        </div>
                        <div className="col">
                            <ModalAlert
                                show={this.state.alertShow}
                                closeAlert={() => this.closeAlert()}
                                messageType={this.state.alertMessageType}
                                message={this.state.alertMessage}
                            />
                        </div>
                        <div className="col text-right">
                            {!this.state.showEditor &&
                                <a href="#" onClick={this.toggleConnectionsModal} disabled={!this.canAddRegistration()} onClick={() => this.newRegistration()} className="looks-clickable">
                                    <i className="fa fa-plus mr-1"></i>
                                </a>
                            }
                        </div>
                    </div>
                    <br />
                    <div>
                        <table className="table full-width">
                            <thead>
                                <tr>
                                    <th>
                                        Registration Number
                                    </th>
                                    <th> {/* Card#5741 - update service type into profession */}
                                        Profession
                                    </th>
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && registrationRecords.map(registrationRecord => {
                                    return <tr key={registrationRecord.id}>
                                        <td>
                                            {registrationRecord.registrationNumber}
                                        </td>
                                        <td>
                                            {registrationRecord.profession}
                                        </td>
                                        <td className="td-action">
                                            <a className="icon-options-vertical link" href="#" style={{ float: "right" }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                <i className="fa fa-ellipsis-v text-color-blue" aria-hidden="true"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="#" onClick={(evt) => { evt.preventDefault(); this.deleteRegistration(registrationRecord); }} className="dropdown-item">Remove</a>
                                            </div>
                                        </td>
                                    </tr>;
                                })}
                                {!this.state.isLoading && this.state.showEditor &&
                                    <tr>
                                        <td className="td-action form-group">
                                            <Form.Control
                                                type={"text"}
                                                className={`form-control`}
                                                onChange={value => this.registrationNumberChange(value)}
                                            />
                                        </td>
                                        <td className="td-action form-group">
                                            <DropdownFilterNoTitle
                                                selectedId={this.state.selectedProfessions ? this.state.selectedProfessions : ''}
                                                defaultName="Select"
                                                elements={this.state.dropdownProfessions}
                                                onChange={value => this.professionChange(value)} />
                                        </td>
                                        <td className="td-action text-right">
                                            <Button variant="secondary" onClick={() => this.cancelAdd()}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={() => this.save()}>
                                                {"Save"}
                                            </Button>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {this.state.isLoading &&
                            <h3 className="text-center font-weight-bold">
                                <i className="fas fa-spinner fa-spin"></i> Loading
                            </h3>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.close()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}
