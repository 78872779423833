import React, {useContext} from 'react';
import { MultiSelect } from '@practicehub/central';
import {PermissionsContext} from '../../PermissionsContext';

const RegistrationListFilterPractice = ({selectedPractices, practiceFilterChange, practices}) => {

    const { canManageSite } = useContext(PermissionsContext);

    if(!canManageSite) return null;

    return <MultiSelect
        id='practiceFilter'
        label='Practice'
        placeholder='Practice'
        enableAllOption={true}
        values={selectedPractices}
        onChange={practiceFilterChange}
        options={[...practices]}
    />

}

export default RegistrationListFilterPractice;